<template>
    <section class="hero is-fullheight is-auth">
        <div class="hero-body">
            <div class="container has-text-centered">
                <div class="columns">
                    <div class="column is-one-third is-offset-one-third">
                        <slot name="heading"></slot>
                        <br>
                        <div class="box">
                            <slot></slot>
                        </div>
                        <p class="has-text-grey">
                            <a v-if="!isLogin" href="/">Login</a>
                            <a v-else          href="/forgot-password">Passwort vergessen?</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>



<script>
export default {
    props: {
        isLogin: { type: Boolean, default: true }
    }
}
</script>
