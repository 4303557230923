<template>
    <auth-layout>
        <div slot="heading">
            <h3 class="title has-text-grey">Login</h3>
            <p class="subtitle has-text-grey">Bitte einloggen.</p>
        </div>

        <form>
            <div class="field">
                <div class="control">
                    <input class="input" type="text" name="buspaket_email" placeholder="Username" autofocus="" v-model="username">
                </div>
            </div>

            <div class="field">
                <div class="control">
                    <input class="input" type="password" name="buspaket_password" placeholder="Passwort" v-model="password">
                </div>
            </div>
            <div class="field">
                <label class="checkbox">
                    <input type="checkbox">
                    Eingeloggt bleiben
                </label>
            </div>
            <button
                class="button is-primary is-fullwidth"
                v-bind:class="{ 'is-loading': isLoading }"
                v-on:click.prevent.stop="login"
            >Login</button>
        </form>
    </auth-layout>
</template>



<script>
import { notifySuccess, notifyError } from '@components/Notification';
import { login } from '@api';
import AuthLayout from './Layout';


export default {
    data: function () {
        return {
            username: '',
            password: '',

            isLoading: false
        }
    },

    computed: {
        forwardTo: function() {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('redirect') || '/user-dashboard'
        }
    },

    methods: {
        login: function () {
            this.isLoading = true;
            const urlParams = new URLSearchParams(window.location.search);

            login({ username: this.username, password: this.password })
                .then(() => {
                    notifySuccess('Erfolg!');

                    window.location.replace(this.forwardTo);

                }, error => { notifyError('Falsches Passwort oder Benutzer!'); })
                .then(() => { this.isLoading = false; });
        }
    },


    components: {
        AuthLayout
    }
}
</script>
