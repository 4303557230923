var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("auth-layout", [
    _c("div", { attrs: { slot: "heading" }, slot: "heading" }, [
      _c("h3", { staticClass: "title has-text-grey" }, [_vm._v("Login")]),
      _vm._v(" "),
      _c("p", { staticClass: "subtitle has-text-grey" }, [
        _vm._v("Bitte einloggen."),
      ]),
    ]),
    _vm._v(" "),
    _c("form", [
      _c("div", { staticClass: "field" }, [
        _c("div", { staticClass: "control" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.username,
                expression: "username",
              },
            ],
            staticClass: "input",
            attrs: {
              type: "text",
              name: "buspaket_email",
              placeholder: "Username",
              autofocus: "",
            },
            domProps: { value: _vm.username },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.username = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "field" }, [
        _c("div", { staticClass: "control" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password",
              },
            ],
            staticClass: "input",
            attrs: {
              type: "password",
              name: "buspaket_password",
              placeholder: "Passwort",
            },
            domProps: { value: _vm.password },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.password = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "field" }, [
        _c("label", { staticClass: "checkbox" }, [
          _c("input", { attrs: { type: "checkbox" } }),
          _vm._v("\n                Eingeloggt bleiben\n            "),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button is-primary is-fullwidth",
          class: { "is-loading": _vm.isLoading },
          on: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.login.apply(null, arguments)
            },
          },
        },
        [_vm._v("Login")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }