'use strict';

import '@root/bootstrap';

import Login          from './Login.vue';
import ForgotPassword from './ForgotPassword.vue';


let App   = Login;
const url = window.location.pathname;


// Pseudo Routing
switch (url) {
    case '/forgot-password':  App = ForgotPassword; break;
}

if (App) {
    new Vue({
        render: h => h(App),
    }).$mount('#main-app');
}
