var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("auth-layout", { attrs: { isLogin: false } }, [
    _c("div", { attrs: { slot: "heading" }, slot: "heading" }, [
      _c("h3", { staticClass: "title has-text-grey" }, [
        _vm._v("Forgot password?"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "subtitle has-text-grey" }, [
        _vm._v("Enter your email address to proceed."),
      ]),
    ]),
    _vm._v(" "),
    _c("form", [
      _c("div", { staticClass: "field" }, [
        _c("div", { staticClass: "control" }, [
          _c("input", {
            staticClass: "input",
            attrs: { type: "email", placeholder: "Your Email", autofocus: "" },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "button is-primary is-fullwidth" }, [
        _vm._v("Next"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }