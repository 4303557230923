var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "hero is-fullheight is-auth" }, [
    _c("div", { staticClass: "hero-body" }, [
      _c("div", { staticClass: "container has-text-centered" }, [
        _c("div", { staticClass: "columns" }, [
          _c(
            "div",
            { staticClass: "column is-one-third is-offset-one-third" },
            [
              _vm._t("heading"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "box" }, [_vm._t("default")], 2),
              _vm._v(" "),
              _c("p", { staticClass: "has-text-grey" }, [
                !_vm.isLogin
                  ? _c("a", { attrs: { href: "/" } }, [_vm._v("Login")])
                  : _c("a", { attrs: { href: "/forgot-password" } }, [
                      _vm._v("Passwort vergessen?"),
                    ]),
              ]),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }