<template>
    <auth-layout v-bind:isLogin="false">
        <div slot="heading">
            <h3 class="title has-text-grey">Forgot password?</h3>
            <p class="subtitle has-text-grey">Enter your email address to proceed.</p>
        </div>
        <form>
          <div class="field">
            <div class="control">
              <input class="input" type="email" placeholder="Your Email" autofocus="">
            </div>
          </div>
          <button class="button is-primary is-fullwidth">Next</button>
        </form>
    </auth-layout>
</template>

<script>
import AuthLayout from './Layout';
export default {
    components: {
        AuthLayout
    }
}
</script>
